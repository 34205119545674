import axios from 'axios'

export const search = async function (searchText) {
    const limit = 30
    const baseURL = `${process.env.GATSBY_SEARCH_API_URL}`

    let apiUrl = baseURL + '/getbooks/?query=' + searchText + '&limit=' + limit

    if(searchText === "")
        apiUrl = baseURL + '/getbooks?limit=10'

    const response = await axios( apiUrl)  

    return response 
}