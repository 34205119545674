import React from 'react';
import TopBooks from '../topbooks'

import { StaticQuery, graphql } from "gatsby"

function NoMatch({query}){
    return(
        <div className="message">
            <h2>Sorry, we could not find any content for '{query}'</h2>
            <h5>Here is a list of most recommended books</h5>
            <StaticQuery
                query={graphql`
                {
                    allTopBook {
                      edges {
                        node {
                          id
                          title: _id
                          affiliate_link
                          geniusLink
                          indieboundLink
                          thumbnail
                          genre
                          recommendors {
                            reco_tweet
                            user_name
                            user_screen_name
                          }
                          number_of_recommendors
                        }
                      }
                    }
                  }
                `}
                render={data => <TopBooks topbooks={data.allTopBook.edges}/>}
            ></StaticQuery>
        </div>
    )
}

export default NoMatch;