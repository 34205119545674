import React from 'react'
import { Card } from "react-bootstrap"

import Reco from "../reco"
import { hyphenatedName } from '../../services/format'

function SearchItem({match}){
    const { tweet_id, text, title, author, thumbnail, affiliateLink, indieboundLink, geniusLink} = match
    const books = [{
        title: title,
        author: author,
        thumbnail: thumbnail,
        affiliateLink: affiliateLink,
        indieboundLink: indieboundLink,
        geniusLink: geniusLink
    }]
    const hyphen_title = hyphenatedName(title)
    
    const reco = {
        tweet_id: tweet_id,
        text: text,
        bookObjects: books
    }

    return(
        <Card id={ tweet_id + '_' + hyphen_title} style = {{marginBottom: "30px"}}> 
            <Card.Body>
                <Reco reco={reco} />  
            </Card.Body> 
        </Card>
    )
}

export default SearchItem
