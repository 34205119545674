import React, { useState, useEffect } from "react"

import { useLocation } from '@reach/router'
import queryString from 'query-string'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchItem from "../components/searchitem"
import NoMatch from "../components/nomatch"

import { search } from "../services/search"

const getSearchText = (query) =>{
    let searchText = null;

    if (query){
        const searchObj= queryString.parse(query);
        if(Object.hasOwnProperty.bind(searchObj)('q'))
            searchText = searchObj.q
    } 
     
    return searchText;
}

const Search = () => {
    const [ results, setResults ] = useState([])
    const [matchfound, setMatchFound] = useState(true)
    const location = useLocation();
    const searchText = (location.search && getSearchText(location.search)) || '';

    useEffect(() => {
        async function fetchData(){
            const response = await search(searchText)

            if(response.hasOwnProperty('data') && response.data.length > 0){
                setResults(response.data)
                setMatchFound(true)
            }
            else    
                setMatchFound(false)
        }
        fetchData()
    }, [searchText])    
    
    return(
        <Layout>
            <SEO title="BooksChatter | Explore Book Recommendations by Experts" />
            {matchfound ?
            <ul style={{ padding: 0 }}>
                {results.map( reco => {
                    const id = reco.__id + '_' + reco.isbn
                    return(
                    <li
                    key={id}
                    style={{
                        textAlign: "center",
                        listStyle: "none",
                        display: "block"
                    }}
                    >
                        <SearchItem match={reco} />
                    </li>
                )}
                )}
            </ul> :
            <NoMatch query={searchText}/> 
            }
        </Layout>
    )
}

export default Search;